<template>
  <b-modal
      id="modal-add-user"
      ref="modal-add-user"
      footer-class="flex-nowrap"
      no-close-on-esc
      no-close-on-backdrop
      centered
  >
    <template #modal-header>
      <h3>{{ $t('modals.addUser.title') }}</h3>
    </template>

    <template #default>
      <modal-invite-user-and-set-role
          :userName="name"
          :userEmail="email"
          :userRole="role"
          :current-user-role="currentUserRole"
          :isSysAdmin="isSysAdmin"
          :userPrivileges="userPrivileges"
          :inTeam="inTeam"
          @update-fields="updateFields"
          @enter-pressed="addUser"
      ></modal-invite-user-and-set-role>
    </template>

    <template #modal-footer>
      <b-button
          class="p-3 border-right-light-grey"
          block
          @click="cancel"
      >
        {{ $t('buttons.cancel') }}
      </b-button>
      <b-button
          :disabled="!validEmail || !isRoleSet"
          variant="primary"
          class="p-3"
          block
          @click="addUser"
      >
        {{ $t('modals.addUser.validate') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalAddNewUser',
  components: {
    ModalInviteUserAndSetRole: () => import('@/components/modals/ModalInviteUserAndSetRole.vue'),
  },
  props: ['inTeam', 'isSysAdmin', 'userPrivileges', 'currentUserRole'],
  data: () => ({
    name: null,
    email: null,
    role: null,
    validEmail: false,
  }),
  mounted() {
    this.$refs['modal-add-user'].show();
  },
  computed: {
    isRoleSet() {
      return this.role != null;
    },
  },
  methods: {
    updateFields(key, value) {
      this[key] = value;
    },
    addUser() {
      if (this.validEmail && this.isRoleSet) {
        this.$emit('addUser', this.name, this.email.toLowerCase().trim(), this.role);
      }
    },
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style scoped>

</style>
