var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-modal", {
    ref: "modal-add-user",
    attrs: {
      id: "modal-add-user",
      "footer-class": "flex-nowrap",
      "no-close-on-esc": "",
      "no-close-on-backdrop": "",
      centered: ""
    },
    scopedSlots: _vm._u([
      {
        key: "modal-header",
        fn: function() {
          return [_c("h3", [_vm._v(_vm._s(_vm.$t("modals.addUser.title")))])]
        },
        proxy: true
      },
      {
        key: "default",
        fn: function() {
          return [
            _c("modal-invite-user-and-set-role", {
              attrs: {
                userName: _vm.name,
                userEmail: _vm.email,
                userRole: _vm.role,
                "current-user-role": _vm.currentUserRole,
                isSysAdmin: _vm.isSysAdmin,
                userPrivileges: _vm.userPrivileges,
                inTeam: _vm.inTeam
              },
              on: {
                "update-fields": _vm.updateFields,
                "enter-pressed": _vm.addUser
              }
            })
          ]
        },
        proxy: true
      },
      {
        key: "modal-footer",
        fn: function() {
          return [
            _c(
              "b-button",
              {
                staticClass: "p-3 border-right-light-grey",
                attrs: { block: "" },
                on: { click: _vm.cancel }
              },
              [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
            ),
            _c(
              "b-button",
              {
                staticClass: "p-3",
                attrs: {
                  disabled: !_vm.validEmail || !_vm.isRoleSet,
                  variant: "primary",
                  block: ""
                },
                on: { click: _vm.addUser }
              },
              [_vm._v(" " + _vm._s(_vm.$t("modals.addUser.validate")) + " ")]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }